<template>
    <div class="mx-auto my-orders-container">
        <div class="one">
            <div class="leftSideInfo1">
                <p class="bold bigFont ma-0">{{ $t("Customer.MyOrders.Order_Name") }}:
                    <span class="bold"> {{ order.order_name }} </span> 
                </p>
                <p class="ma-0">{{ $t("Customer.MyOrders.Items") }}:
                    <!-- Total Items - 1 => To remove 'shipping' product -->
                    <span class="bold"> {{ order.total_items - 1 }} </span>
                </p>
                <p class="ma-0" v-if="order.order_status==='Packing'">{{ $t("Customer.MyOrders.Tracking_ID") }}: 
                    <span class="bold"> {{ order.tracking_id }} </span>
                </p>
            </div>
            <div class="rightSideInfo1">
                <p class="light-grey-color-text ma-0">{{ $t("Customer.MyOrders.Order_Status") }}</p>
                <div
                class="orderStatus"
                :style="{
                     backgroundColor: [(finalOrderStatus==='Invalid Prescription' || finalOrderStatus==='Cancelled' || finalOrderStatus==='Returned') ? '#EB5757' : (finalOrderStatus==='Delivered' ? '#27AE60' :( finalOrderStatus==='Placed' ? '#1976D2':'#F89035'))],                    
                }">
                    {{ finalOrderStatus }}
                </div>
            </div>
        </div>
        <v-divider style="width: 95%; margin-left: 2.5%;"></v-divider>
        <div class="two">
            <div class="leftSideInfo2">
                <p class="light-grey-color-text ma-0">{{ $t("Customer.MyOrders.Order_Placed_On") }}: 
                    <span class="bold medFont dark-grey-color-text"> {{orderPlacedDate}} </span>
                </p>
            </div>
            <div class="rightSideInfo2">
                <div class="trackOrder" @click="viewParticularOrder()">
                    <p class="ma-0" v-if="order.order_status==='Packaging'">{{ $t("Customer.MyOrders.Track_Order") }}</p>
                    <p class="ma-0" v-else>{{ $t("Customer.MyOrders.View_Details") }}</p>
                    <img height="17px" width="17px" src="https://s3iconimages.mymedicine.com.mm/RightArrowBlue_SearchResult.svg" alt="Go to arrow">
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    name: 'OrderMobileComponent',
    props: [
        'order',
    ],
    data() {
        return {
            orderPlacedDate: '',
            finalOrderStatusList: [],
            finalOrderStatus:''
        }
    },
    mounted() {
        // let resultDate = this.convertEpochToDate(this.order.order_placed_epoch);
        // this.orderPlacedDate = resultDate.day + ' ' + resultDate.month.substring(0, 3) + ", " + resultDate.year;
        this.equalentOrderStatus();
        let dateObj = new Date(this.order.order_placed_date);
        this.orderPlacedDate = dateObj.getDate() + ' ' + dateObj.toLocaleString('default', { month: 'short' }) + ', ' + dateObj.getFullYear();
    },
    methods: {
        viewParticularOrder() {
            this.$router.push({
                path: "/customer/orderDetailsPage/"+this.order.order_id
            });
        },
        convertEpochToDate(epochTime) {
            const date = new Date(epochTime);
            const day = date.toLocaleString("en-US", { day: "numeric" });
            const month = date.toLocaleString("en-US", { month: "long" });
            const year = date.toLocaleString("en-US", { year: "numeric" });
            return {day, month, year};
        },
        equalentOrderStatus(){
            let orderStatus = this.order.order_status;
            let correctSpell = '';
            switch(orderStatus){
                case 'placed' :
                    correctSpell = 'Placed';
                    break;
                case 'in_process' :
                    correctSpell = 'In Process';
                    break;
                case 'waiting' :
                    correctSpell = 'In Process';
                    break;
                case 'postponed' :
                    correctSpell = 'In Process';
                    break;
                case 'to_be_delivery' :
                    correctSpell = 'To Be Delivered';
                    break;
                case 'in_delivery' :
                    correctSpell = 'On The Way';
                    break;
                case 'delivered' : {
                    correctSpell = 'Delivered';
                    break;
                }
                case 'canceled' :
                    correctSpell = 'Cancelled';
                    break;
                case 'returned' :
                    correctSpell = 'Returned';
                    break;
            }
            this.finalOrderStatusList.push(correctSpell);
            this.finalOrderStatus=this.finalOrderStatusList[this.finalOrderStatusList.length-1];
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/global.scss';
@import '../../scss/classes.scss';
.bigFont {
    font-size: 14px;
}
.medFont {
    font-size: 12px;
}
.lightBold {
    font-weight: 600;
}
.bold {
    font-weight: 600;
}
.orderStatus {
    font-weight: 600;
    color: white;
    height: 40%;
    width: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.trackOrder {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;

    color: #48ACEF; 
    background-color: white; 
    text-transform: none; 
    font-size: 12px;
    cursor: pointer;
}
.leftSideInfo2 {
    padding-left: 16px;
    text-align: left;
}
.rightSideInfo2 {
    display: flexbox;
}
.leftSideInfo1 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    text-align: left;
    line-height: 20px;
}
.rightSideInfo1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // align-items: flex-end;
    align-items: center;
    margin-right: 10px;
    padding: 10px !important;
    // background-color: rebeccapurple;
}
.two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.one {
    display: flex;
    justify-content: space-between;
    height: 100px;
}
.my-orders-container {
    width: 85%;
    border: 1px solid #E0E0E0 !important;
    border-radius: 8px;
    font-size: 12px;
}
</style>